import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import star from '../assets/star.svg';
import heart from '../assets/heart.svg';
import red_heart from '../assets/heart-fill-red.svg';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import TagManager from 'react-gtm-module';
import LoadingSpinner from './LoadingSpinner';

const Restaurant = ({ id, name, city, shortDesc, logo, avg, userid, isFavorite: initialIsFavorite }) => {
  const navigate = useNavigate();
  const [isFavorite, setIsFavorite] = useState(initialIsFavorite);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);

  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const s3url = process.env.REACT_APP_S3_URL || '';

  const getImageUrl = () => {
    if (s3url) {
      return `${s3url}/Images/restaurants/${id}/${logo}`;
    }
    return `${apiUrl}/images/restaurants/${id}/${logo}`;
  };

  const goRestaurant = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'go_restaurant',
        source: 'List',
        id_restaurant: id,
      },
    });
    navigate(`/Restaurant?id=${id}`);
  };

  const toggleFavorite = async (e) => {
    e.stopPropagation();
    TagManager.dataLayer({
      dataLayer: {
        event: 'favorite_tried'
      },
    });
    
    if (!userid) {
      navigate('/Login');
      return;
    }

    try {
      await fetch(`${apiUrl}/toggle-like`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ restaurantId: id, userid }),
        credentials: 'include',
      });

      setIsFavorite(!isFavorite);
    } catch (error) {
      console.error('Error toggling favorite:', error);
    }
  };

  return (
    <div onClick={goRestaurant} className="flex w-full max-w-md rounded overflow-hidden shadow-lg bg-white m-4 cursor-pointer">
      <div className="flex-none w-2/5 relative">
        {!imageLoaded && !imageError && (
          <div className="w-full h-[120px] bg-gray-200 animate-pulse rounded-lg" />
        )}
        
        {!imageError && (
          <img
            src={getImageUrl()}
            className={`w-full h-[120px] object-cover rounded-lg shadow-lg transition-opacity duration-300 ${
              imageLoaded ? 'opacity-100' : 'opacity-0'
            }`}
            alt={name}
            loading="lazy"
            onLoad={() => {
              setImageLoaded(true);
              setImageError(false);
            }}
            onError={() => {
              setImageError(true);
              setImageLoaded(true);
            }}
          />
        )}

        {imageError && (
          <div className="w-full h-[120px] bg-gray-100 rounded-lg flex items-center justify-center">
            <span className="text-gray-500 text-sm">{name.charAt(0)}</span>
          </div>
        )}

        <div className='mt-5'>
          <img
            src={isFavorite ? red_heart : heart}
            alt="Favorite"
            className="h-6 w-6 mx-auto block"
            onClick={toggleFavorite}
          />
        </div>
      </div>
      <div className="w-3/5 p-4 flex flex-col justify-between">
        <div>
          <div className="font-bold text-lg mb-2">{name}</div>
          <div className="flex items-center mb-2">
            <img className="fw-[27px] h-[24px]" alt="Rating star" src={star} />
            <p className="ml-4 font-h1 font-bold text-h1">{avg}</p>
          </div>
          <p className="text-gray-700 text-sm mb-2">{city}</p>
        </div>
        <p className="text-gray-700 text-sm">{shortDesc}</p>
      </div>
    </div>
  );
};

const getDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the earth in km
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in km
  return distance;
};

const Map = ({ restaurants }) => {
  console.log('Restaurants data:', restaurants);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyChWQp9d1V91YwEP-dsKyj3jn6FvNKYjOw' // Replace with your actual API Key
  });

  const containerStyle = {
    width: '100%',
    height: '400px'
  };

  const [center, setCenter] = useState({ lat: 4.644543473479167, lng: -74.09166139855665 });
  const navigate = useNavigate();

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
        },
        (error) => {
          console.error("Error obtaining geolocation", error);
        }
      );
    }
  }, []);

  const handleMarkerClick = (restaurantId) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'go_restaurant',
        source: 'Map',
        id_restaurant: restaurantId,
      },
    });
    navigate(`/Restaurant?id=${restaurantId}`);
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  if (!isLoaded) {
    return <div>Loading Maps...</div>;
  }

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={16}
    >
      {restaurants.map(restaurant => (
        restaurant.latitude && restaurant.longitude && (
          <Marker
            key={restaurant.restaurantid}
            position={{ lat: parseFloat(restaurant.latitude), lng: parseFloat(restaurant.longitude) }}
            label={`(${restaurant.overall_average}) ${restaurant.restaurantname}`}
            onClick={() => handleMarkerClick(restaurant.restaurantid)}
          />
        )
      ))}
    </GoogleMap>
  );
};

const Restaurants = ({ searchTerm, selectedCategory, userid, filters, showFavorites }) => {
  const [restaurants, setRestaurants] = useState([]);
  const [visibleRestaurants, setVisibleRestaurants] = useState(8);
  const [userLocation, setUserLocation] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showMap, setShowMap] = useState(false);

  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
        },
        (error) => {
          console.error("Error obtaining geolocation", error);
        }
      );
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (userid) {
          await getRestaurants();
        } else {
          await getAllRestaurants();
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [userid]);

  const removeDiacritics = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const filteredRestaurants = restaurants.filter(restaurant => {
    const foodNames = restaurant.food_names || '';
    const searchTermNormalized = removeDiacritics(searchTerm.toLowerCase());

    const matchesSearchTerm = removeDiacritics(restaurant.restaurantname.toLowerCase()).includes(searchTermNormalized) || 
                              removeDiacritics(foodNames.toLowerCase()).includes(searchTermNormalized) || 
                              removeDiacritics(restaurant.short_desc.toLowerCase()).includes(searchTermNormalized);

    const foodCategories = restaurant.food_categories || '';
    const matchesCategory = selectedCategory ? removeDiacritics(foodCategories.toLowerCase()).includes(removeDiacritics(selectedCategory.toLowerCase())) : true;
    const matchesCity = filters.city ? removeDiacritics(restaurant.city.toLowerCase()) === removeDiacritics(filters.city.toLowerCase()) : true;
    const matchesRating = filters.rating ? parseFloat(restaurant.overall_average) >= filters.rating : true;
    const matchesPriceRange = filters.priceRange ? parseFloat(restaurant.avg_plato_fuerte_price) >= filters.priceRange[0] && parseFloat(restaurant.avg_plato_fuerte_price) <= filters.priceRange[1] : true;
    const matchesSurveyCount = filters.surveyCount ? parseInt(restaurant.total_surveys) >= filters.surveyCount : true;
    const matchesFavorites = !showFavorites || (showFavorites && restaurant.isFavorite);
    const matchesPetFriendly = filters.petFriendly ? restaurant.pet_friendly === true : true;

    const distance = filters.distance ||15 ;
    const matchesDistance = userLocation && distance
      ? getDistance(userLocation.lat, userLocation.lng, parseFloat(restaurant.latitude), parseFloat(restaurant.longitude)) <= distance
      : true;

    return matchesSearchTerm && matchesCategory && matchesCity && matchesRating && matchesPriceRange && matchesSurveyCount && matchesFavorites && matchesPetFriendly && matchesDistance;
  });

  const navigate = useNavigate();

  useEffect(() => {
    setVisibleRestaurants(8);
  }, [restaurants]);

  const getRestaurants = async () => {
    try {
      const queryParams = new URLSearchParams();
      if (userid) {
        queryParams.append('userid', userid);
      }
      console.log('UserID:', userid);
      const link = `${apiUrl}/res?${queryParams.toString()}`;
      console.log(link);
      const response = await fetch(link, { credentials: 'include' });
      const jsonData = await response.json();
      setRestaurants(jsonData.map(restaurant => ({
        ...restaurant,
        isFavorite: restaurant.is_favorite,
      })));
    } catch (err) {
      console.error(err.message);
    }
  };

  const getAllRestaurants = async () => {
    try {
      const link = `${apiUrl}/res`;
      console.log(link);
      const response = await fetch(link, { credentials: 'include' });
      const jsonData = await response.json();
      setRestaurants(jsonData.map(restaurant => ({
        ...restaurant,
        isFavorite: restaurant.is_favorite,
      })));
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 10) {
      loadMoreRestaurants();
    }
  };

  const loadMoreRestaurants = () => {
    setVisibleRestaurants((prevVisibleRestaurants) => prevVisibleRestaurants + 5);
  };

  const toggleView = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'change_view_map',
        view: showMap,
      },
    });
    setShowMap(!showMap);
  };

  return (
    <div className="container mx-auto px-4 mb-20">
      <button 
        className="bg-navbar hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full fixed bottom-24 left-1/2 transform -translate-x-1/2 z-50"
        onClick={toggleView}
      >
        {showMap ? 'Lista' : 'Mapa'}
      </button>

      {isLoading ? (
        <LoadingSpinner />
      ) : showMap ? (
        <Map restaurants={filteredRestaurants} />
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {filteredRestaurants.slice(0, visibleRestaurants).map(restaurant => (
            <Restaurant
              key={restaurant.restaurantid}
              id={restaurant.restaurantid}
              name={restaurant.restaurantname}
              city={restaurant.city}
              shortDesc={restaurant.short_desc}
              logo={restaurant.logo}
              userid={userid}
              avg={restaurant.overall_average}
              isFavorite={restaurant.isFavorite}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Restaurants;

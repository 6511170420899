import ig from '../assets/instagram.svg';
import yt from '../assets/youtube.svg';
import whatsapp from '../assets/whatsapp.svg';
import email from '../assets/email.svg';
import tiktok from '../assets/tiktok.svg';

const Footer = () => {
    return (
        <footer className="bg-navbar text-white p-1 text-center fixed bottom-0 w-full">
            <div className="flex justify-center items-center gap-4">
                <a href="https://www.instagram.com/eats.opinion/" 
                   target="_blank" 
                   rel="noopener noreferrer"
                   className="hover:opacity-80 transition-opacity p-1">
                    <img src={ig} alt="Instagram" className="h-10 w-10" />
                </a>
                <p className="font-h1">|</p>
                
                <a href="https://www.youtube.com/@Eatsopinion/videos" 
                   target="_blank" 
                   rel="noopener noreferrer"
                   className="hover:opacity-80 transition-opacity p-1">
                    <img src={yt} alt="Youtube" className="h-10 w-10" />
                </a>
                <p className="font-h1">|</p>
                
                <a href="https://www.tiktok.com/@eatsopinion" 
                   target="_blank" 
                   rel="noopener noreferrer"
                   className="hover:opacity-80 transition-opacity p-1">
                    <img src={tiktok} alt="TikTok" className="h-10 w-10" />
                </a>
                <p className="font-h1">|</p>
                
                <a href="https://wa.me/573013879648" 
                   target="_blank"
                   rel="noopener noreferrer"
                   className="hover:opacity-80 transition-opacity p-1">
                    <img src={whatsapp} alt="WhatsApp" className="h-10 w-10" />
                </a>
                <p className="font-h1">|</p>
                
                <a href="mailto:eatsopinon@gmail.com" 
                   className="hover:opacity-80 transition-opacity p-1">
                    <img src={email} alt="Email" className="h-10 w-10" />
                </a>
            </div>
        </footer>
    );
};

export default Footer;
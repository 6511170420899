import React from 'react';

const LoadingSpinner = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-[200px]">
      <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-b-4 border-navbar mb-4"></div>
      <p className="text-lg text-navbar font-semibold">
        Cargando los mejores restaurantes para ti...
      </p>
    </div>
  );
};

export default LoadingSpinner; 